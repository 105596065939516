import logo from './logo.svg';
import './App.css';
import ChatConversation from './ChatConversation';
import { MicrophoneProvider } from './providers/MicrophoneProvider';
import { DeepgramProvider } from './providers/DeepgramStreamProvider';


function App() {
  return (
    <div className="App">
      <div className="container mt-4 mx-auto col-md-3 col-12 text-center">
        <h1>AI Sales</h1>
        <MicrophoneProvider>
          <DeepgramProvider>
            <ChatConversation />
          </DeepgramProvider>
        </MicrophoneProvider>
      </div>
    </div>
  );
}

export default App;
