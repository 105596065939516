// src/audioPreloader.js
const audioCache = {};

export const preloadAudios = (audioFiles) => {
  Object.keys(audioFiles).forEach((word) => {
    const filePaths = Array.isArray(audioFiles[word]) ? audioFiles[word] : [audioFiles[word]];
    filePaths.forEach((path, index) => {
      const audio = new Audio(path);
      const key = `${word}_${index}`;
      audioCache[key] = audio;
    });
  });
};

export const getAudio = (word) => {
  const keys = Object.keys(audioCache).filter(key => key.startsWith(word));
  if (keys.length > 0) {
    const randomIndex = Math.floor(Math.random() * keys.length);
    return audioCache[keys[randomIndex]];
  }
  return null;
};
