import logo from './logo.svg';
import image1 from './image.webp';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import useChatAssistant from './hooks/useChatAssistant';
import { useMicrophone } from './providers/MicrophoneProvider';
import { useDeepgram } from './providers/DeepgramStreamProvider';

const ChatConversation = () => {
  const audioRef = useRef(null);
  const mediaSourceRef = useRef(null);
  const sourceBufferRef = useRef(null);
  const fadeDuration = 350;
  const { audioChunks, setAudioChunks, startRecording, stopRecording, pauseRecording, hasMicPermission } = useMicrophone();
  const { deepgramService, result } = useDeepgram();
  useEffect(() => {
    if (audioChunks.length > 0) {
      const latestChunk = audioChunks[audioChunks.length - 1];
      deepgramService.sendAudio(latestChunk);

      // Remove the latest chunk from audioChunks
      setAudioChunks(prevChunks => prevChunks.slice(0, -1));
    }
  }, [audioChunks, deepgramService, setAudioChunks]);

  const initializeMediaSource = useCallback(() => {
    if (audioRef.current) {
      mediaSourceRef.current = new MediaSource();
      audioRef.current.src = URL.createObjectURL(mediaSourceRef.current);

      mediaSourceRef.current.addEventListener('sourceopen', () => {
        if (mediaSourceRef.current) {
          sourceBufferRef.current = mediaSourceRef.current.addSourceBuffer('audio/mpeg');
          sourceBufferRef.current.mode = 'sequence';
        }
      }, { once: true });
    }
  }, []);

  useEffect(() => {
    initializeMediaSource();
  }, [initializeMediaSource]);

  useEffect(() => {
    if (result) {
      console.log('Transcription result:', result); 
      if (result !== '') {
        sendMessage(result);
        setNewMessage('');
      }  
    }
    else {
      stopAudioStream();
    }    
  }, [result]);

  const fadeVolume = (targetVolume, duration) => {
    if (audioRef.current) {
      const startVolume = audioRef.current.volume;
      const volumeChange = targetVolume - startVolume;
      const startTime = performance.now();

      const fade = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        const fraction = Math.min(elapsedTime / duration, 1);
        audioRef.current.volume = Math.min(Math.max(startVolume + volumeChange * fraction, 0), 1);

        if (fraction < 1) {
          requestAnimationFrame(fade);
        }
      };

      requestAnimationFrame(fade);
    }
  };

  const fadeInAudio = () => {
    fadeVolume(1, fadeDuration);
  };

  const fadeOutAudio = () => {
    fadeVolume(0, fadeDuration);
  };

  const stopAudioStream = useCallback(() => {
    if (audioRef.current) {
      fadeOutAudio();
      setTimeout(() => {
        audioRef.current.pause();
        if (mediaSourceRef.current && mediaSourceRef.current.readyState === 'open') {
          mediaSourceRef.current.endOfStream();
        }
        mediaSourceRef.current = null;
        sourceBufferRef.current = null;
        initializeMediaSource();
      }, fadeDuration);
    }
  }, [initializeMediaSource]);

  const handleAudioChunk = useCallback((chunk) => {
    if (sourceBufferRef.current && !sourceBufferRef.current.updating) {
      try {
        sourceBufferRef.current.appendBuffer(chunk);
        if (audioRef.current.paused) {
          audioRef.current.play();
          fadeInAudio();
        }
      } catch (e) {
        console.error('Failed to append buffer:', e);
      }
    } else if (sourceBufferRef.current) {
      sourceBufferRef.current.addEventListener('updateend', () => {
        if (!sourceBufferRef.current.updating) {
          try {
            sourceBufferRef.current.appendBuffer(chunk);
            if (audioRef.current.paused) {
              audioRef.current.play();
              fadeInAudio();
            }
          } catch (e) {
            console.error('Failed to append buffer:', e);
          }
        }
      }, { once: true });
    }
  }, []);

  const { messages, sendMessage, enqueueInitialMessage } = useChatAssistant(handleAudioChunk, stopAudioStream);
  // const { messages, sendMessage } = useChatAssistant(handleAudioChunk, stopAudioStream);
  const [newMessage, setNewMessage] = useState('');

  const handleSendMessage = () => {
    sendMessage(newMessage);
    setNewMessage('');
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const formatMsToSeconds = (ms) => (ms / 1000).toFixed(1);

  const handleStartRecording = () => {
    enqueueInitialMessage();
    startRecording();
  };

  return (
    <div>
      <ul className="list-group d-none">
        {messages.map((msg, index) => (
          <li key={index} className="list-group-item">
            <strong>{msg.role}</strong>: {msg.text}
            <div className="text-muted small mt-1">
              LLM (TTFB: {formatMsToSeconds(msg.llmFirstByte)} s, Total: {formatMsToSeconds(msg.llmTotal)} s) <br />
              TTS (TTFB: {formatMsToSeconds(Math.max(msg.ttsFirstByte - msg.llmFirstByte, 0))} s, Total: {formatMsToSeconds(Math.max(msg.ttsTotal - msg.llmFirstByte, 0))} s)
            </div>
            {msg.audios && msg.audios.length > 0 && (
              <ul className="list-group mt-2">
                {msg.audios.map((audio, audioIndex) => (
                  <li key={audioIndex} className="list-group-item">
                    <audio
                      controls
                      src={audio}
                    ></audio>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
      <div className="input-group mt-3 d-none">
        <textarea
          className="form-control"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <button className="btn btn-primary" onClick={handleSendMessage}>
          Send
        </button>
      </div>
      <audio ref={audioRef} controls className='d-none'></audio>

      <div>
        <h2 className='d-none'>Microphone Control</h2>
        <img src={image1} alt='Microphone icon' className='img-fluid' />
        <p><b>Objective:</b> Is to sell Sales Copilot that gives real-time suggestions to sales reps during calls with customers.</p>
        {hasMicPermission ? (
          <div>
            <button onClick={handleStartRecording}>Talk to assistant</button>
            <button onClick={pauseRecording} className='d-none'>Pause Recording</button>
            <button onClick={stopRecording} className='d-none'>Stop Recording</button>
          </div>
        ) : (
          <p>Microphone access is not granted.</p>
        )}
      </div>
    </div>
  );
};

export default ChatConversation;
