import { useState, useRef, useEffect } from 'react';
import { systemContent } from '../constants';
import { preloadAudios, getAudio } from '../audioPreloader';

const GROQ_API_KEY = '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';
const GROQ_API_URL = 'https://api.groq.com/openai/v1/chat/completions';
const TTS_API_KEY = 'sk-proj-qsdB0ItZ1ld3qeQux3zyT3BlbkFJW5npHqy2zltAxnPiwvGE';
const TTS_API_URL = 'https://api.openai.com/v1/audio/speech';

const audioFiles = {
  'certo': ['audios/pt/certo1.mp3', 'audios/pt/certo2.mp3', 'audios/pt/certo3.mp3'],
  'obrigada': ['audios/pt/obrigada1.mp3', 'audios/pt/obrigada2.mp3'],
  'então': ['audios/pt/então1.mp3', 'audios/pt/então2.mp3'],
  'tá': ['audios/pt/tá1.mp3', 'audios/pt/tá2.mp3'],
  'curto': 'audios/pt/certo1.mp3',
  'longo': 'audios/pt/certo2.mp3',
  'x-longo': 'audios/pt/certo3.mp3',
};

const useChatAssistant = (onAudioChunk, stopAudioStream) => {
  const [messages, setMessages] = useState([]);
  const ttsAbortController = useRef(new AbortController());
  const hasPlayedUtterance = useRef(false);

  useEffect(() => {
    preloadAudios(audioFiles);
  }, []);

  const updateMessage = (updates) => {
    setMessages((prevMessages) => {
      const newMessages = [...prevMessages];
      if (newMessages.length > 0) {
        newMessages[newMessages.length - 1] = { ...newMessages[newMessages.length - 1], ...updates };
      }
      return newMessages;
    });
  };

  const sendMessage = async (text) => {
    stopAudioStream();

    const newMessage = { role: 'user', text, llmFirstByte: 0, llmTotal: 0, ttsFirstByte: 0, ttsTotal: 0, audios: [] };
    setMessages((prevMessages) => [...prevMessages, newMessage]);

    ttsAbortController.current.abort();
    ttsAbortController.current = new AbortController();
    hasPlayedUtterance.current = false;

    const llmStart = performance.now();
    try {
      await fetchLLMResponse(text, llmStart);
    } catch (e) {
      if (e.name === 'AbortError') {
        console.log('Previous request was aborted');
      } else {
        console.error('Error fetching LLM response:', e);
      }
    }
  };

  const fetchLLMResponse = async (text, llmStart) => {
    const systemMessage = { role: 'system', content: systemContent };
    const userMessage = { role: 'user', content: text };

    const response = await fetch(GROQ_API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${GROQ_API_KEY}`,
      },
      body: JSON.stringify({
        messages: [systemMessage, ...messages.map((msg) => ({ role: msg.role, content: msg.text })), userMessage],
        model: 'llama3-70b-8192',
        temperature: 1,
        max_tokens: 1024,
        top_p: 1,
        stream: true,
        stop: null,
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to fetch LLM response');
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let result = '';
    let done = false;
    const assistantMessage = { role: 'assistant', text: '', llmFirstByte: 0, llmTotal: 0, ttsFirstByte: 0, ttsTotal: 0, audios: [] };
    const firstByteTime = performance.now();

    assistantMessage.llmFirstByte = Math.round(firstByteTime - llmStart);
    setMessages((prevMessages) => [...prevMessages, assistantMessage]);

    let incompleteChunk = '';

    while (!done) {
      const { value, done: doneReading } = await reader.read();
      done = doneReading;
      const chunk = decoder.decode(value, { stream: true });

      let combinedChunk = incompleteChunk + chunk;
      const lines = combinedChunk.split('\n').filter(line => line.trim() !== '');
      incompleteChunk = '';

      for (let i = 0; i < lines.length; i++) {
        const line = lines[i];

        if (line.startsWith('data:')) {
          const dataLine = line.substring(5).trim();
          if (dataLine === '[DONE]') {
            done = true;
            break;
          }
          if (!dataLine) {
            continue;
          }

          let data;
          try {
            data = JSON.parse(dataLine);
          } catch (err) {
            incompleteChunk = dataLine;
            continue;
          }

          if (data.choices?.[0]?.delta?.content) {
            result += data.choices[0].delta.content;
            assistantMessage.text = result;
            updateMessage(assistantMessage);

            if (!hasPlayedUtterance.current) {
              const match = /\*([^\*]+)\*/.exec(result);
              if (match) {
                hasPlayedUtterance.current = true;
                playUtterance(match[1].toLocaleLowerCase());
              }
            }
          }
        }
      }
    }

    if (incompleteChunk) {
      console.log('Incomplete JSON chunk:', incompleteChunk);
    }

    assistantMessage.llmTotal = Math.round(performance.now() - llmStart);

    updateMessage(assistantMessage);
    enqueueTTSResponse(result, assistantMessage);
  };

  const enqueueTTSResponse = async (text, assistantMessage) => {
    const ttsStartTotal = performance.now();

    try {
      await handleTTSResponse(text, assistantMessage);
    } catch (e) {
      if (e.name === 'AbortError') {
        console.log('TTS request was aborted');
      } else {
        console.error('Error fetching TTS response:', e);
      }
    }

    assistantMessage.ttsTotal = Math.round(performance.now() - ttsStartTotal);
    updateMessage(assistantMessage);
  };

  const handleTTSResponse = async (text, assistantMessage) => {
    text = "*smile* " + text.replace(/\*([^\*]+)\*/, '');

    const ttsStart = performance.now();
    const response = await fetch(TTS_API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${TTS_API_KEY}`,
      },
      body: JSON.stringify({
        model: 'tts-1',
        input: text,
        voice: 'nova',
        speed: 1,
        response_format: 'mp3',
      }),
      signal: ttsAbortController.current.signal,
    });

    if (!response.ok) {
      throw new Error('Failed to fetch TTS response');
    }

    if (!assistantMessage.ttsFirstByte) {
      assistantMessage.ttsFirstByte = Math.round(performance.now() - ttsStart);
      updateMessage(assistantMessage);
    }

    const reader = response.body.getReader();
    let done = false;

    while (!done) {
      const { value, done: doneReading } = await reader.read();
      done = doneReading;
      if (value) {
        onAudioChunk(value.buffer);
      }
    }
  };

  const playUtterance = (word) => {
    const audio = getAudio(word);
    if (audio) {
      audio.play();
    } else {
      console.log('No audio file found for word:', word);
    }
  };

  const enqueueInitialMessage = () => {
    const initialMessage = { role: 'assistant', text: '*smile* oláá! Eu sou Juliana estarei atendendo você hoje. com quem estou falando???', llmFirstByte: 0, llmTotal: 0, ttsFirstByte: 0, ttsTotal: 0, audios: [] };
    setMessages((prevMessages) => [...prevMessages, initialMessage]);
    enqueueTTSResponse(initialMessage.text, initialMessage);
  };

  return { messages, sendMessage, enqueueInitialMessage };
};

export default useChatAssistant;
