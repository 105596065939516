import React, { createContext, useContext, useEffect, useRef, useState, useCallback } from 'react';
import { createClient, LiveTranscriptionEvents } from '@deepgram/sdk';
import { useMicrophone } from './MicrophoneProvider';  // Import useMicrophone

const API_KEY = '3091469f526b96510f09b13bb92e1e7d7c7cecec';
const DeepgramContext = createContext();

class DeepgramStreamService {
    constructor() {
        this.deepgram = createClient(API_KEY);
        this.connection = null;
        this.isConnectionOpen = false;
        this.audioBuffer = [];
        this.onResult = null;
    }

    async startStreamTranscription(onResult) {
        console.log('Starting stream transcription');
        this.onResult = onResult;
        try {
            this.connection = this.deepgram.listen.live({
                model: "nova-2",
                language: "pt",
                smart_format: true,
                punctuate: true,
                sample_rate: 16000,
                numerals: true,
                endpointing: 300,
                interim_results: true,
            });

            this.connection.on(LiveTranscriptionEvents.Open, () => {
                console.info('WebSocket connection opened');
                this.isConnectionOpen = true;

                while (this.audioBuffer.length > 0) {
                    const chunk = this.audioBuffer.shift();
                    this.sendAudio(chunk);
                }
            });

            this.connection.on(LiveTranscriptionEvents.Transcript, (data) => {
                if (data.is_final && data.channel.alternatives[0].words.length > 0) {
                    this.onResult(data);
                }
                else if(data.channel.alternatives[0].words.length > 0) {
                    this.onResult(data);
                }
            });

            this.connection.on(LiveTranscriptionEvents.Close, () => {
                console.info('WebSocket connection closed');
                this.isConnectionOpen = false;
            });

            this.connection.on(LiveTranscriptionEvents.Error, (error) => {
                console.error('WebSocket error:', error);
                this.onError(error.message);
            });
        } catch (error) {
            console.error('Error starting transcription:', error);
            this.onError(error.message);
        }
    }

    stopStreamTranscription() {
        console.log('Stopping stream transcription');
        if (this.connection) {
            if (this.connection.getReadyState() === 1) {
                this.connection.finish();
                console.info('WebSocket connection finished');
                this.isConnectionOpen = false;
            } else {
                console.warn('WebSocket is not open. Cannot call finish.');
            }
        }
    }

    sendAudio(chunk) {
        if (this.isConnectionOpen && this.connection && this.connection.getReadyState() === 1) {
            this.connection.send(chunk);
        } else {
            this.audioBuffer.push(chunk);
        }
    }

    transformResult(data) {
        if (!data.is_final) {
            return "";
        }

        if (data && data.channel && data.channel.alternatives && data.channel.alternatives.length > 0) {
            return data.channel.alternatives[0].transcript.length > 0 ? data.channel.alternatives[0].transcript : null;
        }
        return null;
    }

    onError(message) {
        console.error(message);
    }
}

export const DeepgramProvider = ({ children }) => {
    const deepgramService = useRef(new DeepgramStreamService()).current;
    const [result, setResult] = useState(null);
    const { isRecording } = useMicrophone();  // Use useMicrophone to get isRecording

    const handleResult = useCallback((data) => {
        const transformedData = deepgramService.transformResult(data);
        if (transformedData !== null) {
            setResult(transformedData);
        }
    }, [deepgramService]);

    useEffect(() => {
        if (isRecording) {
            console.log('Microphone is recording, starting transcription');
            deepgramService.startStreamTranscription(handleResult);
        } else {
            console.log('Microphone is not recording, stopping transcription');
            deepgramService.stopStreamTranscription();
        }
    }, [deepgramService, handleResult, isRecording]);

    return (
        <DeepgramContext.Provider value={{ deepgramService, result }}>
            {children}
        </DeepgramContext.Provider>
    );
};

export const useDeepgram = () => {
    return useContext(DeepgramContext);
};
