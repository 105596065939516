import React, { createContext, useContext, useState, useEffect } from 'react';
import { MediaRecorder, register } from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';

const MicrophoneContext = createContext();

export const useMicrophone = () => useContext(MicrophoneContext);

export const MicrophoneProvider = ({ children }) => {
  const [audioChunks, setAudioChunks] = useState([]);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [micStream, setMicStream] = useState(null);
  const [hasMicPermission, setHasMicPermission] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isEncoderRegistered, setIsEncoderRegistered] = useState(false);

  useEffect(() => {
    checkMicPermission();
  }, []);

  const checkMicPermission = async () => {
    try {
      const permissionStatus = await navigator.permissions.query({ name: 'microphone' });
      if (permissionStatus.state === 'granted') {
        setHasMicPermission(true);
      } else if (permissionStatus.state === 'prompt' || permissionStatus.state === 'denied') {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        stream.getTracks().forEach(track => track.stop()); // Close the stream to just get the permission
        setHasMicPermission(true);
      } else {
        setHasMicPermission(false);
      }
      permissionStatus.onchange = () => {
        setHasMicPermission(permissionStatus.state === 'granted');
      };
    } catch (error) {
      setHasMicPermission(false);
    }
  };

  const startRecording = async () => {
    try {
      if (!isEncoderRegistered) {
        await register(await connect());
        setIsEncoderRegistered(true);
      }

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          channelCount: 1,
          echoCancellation: true,
          autoGainControl: true,
          noiseSuppression: true,
        },
        video: false
      });
      setMicStream(stream);
      const recorder = new MediaRecorder(stream, { mimeType: 'audio/wav' });

      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          setAudioChunks((prevChunks) => [...prevChunks, event.data]);
        }
      };

      recorder.onerror = (event) => {
        console.error(event.error.name + ": " + event.error.message);
      };

      recorder.onstop = () => {
        console.log('Recording stopped');
        setIsRecording(false);
      };

      setMediaRecorder(recorder);
      setIsRecording(true);
      recorder.start(50);
    } catch (error) {
      console.error(error.message);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
    }
    if (micStream) {
      micStream.getTracks().forEach(track => track.stop());
    }
    setIsRecording(false);
  };

  const pauseRecording = () => {
    if (mediaRecorder && mediaRecorder.state === 'recording') {
      mediaRecorder.pause();
    }
  };

  return (
    <MicrophoneContext.Provider
      value={{
        audioChunks,
        setAudioChunks,
        startRecording,
        stopRecording,
        pauseRecording,
        hasMicPermission,
        isRecording
      }}
    >
      {children}
    </MicrophoneContext.Provider>
  );
};
